<template>
  <div>
    <!-- AddToAny BEGIN -->
    <div
      class="a2a_kit a2a_kit_size_32 a2a_default_style sns-wrapper"
      data-a2a-title="REFER A FRIEND"
      :data-a2a-url="link"
    >
      <a class="a2a_button_whatsapp mt-2"></a>
      <a class="a2a_button_line mt-2"></a>
      <a class="a2a_button_twitter mt-2"></a>
      <a class="a2a_button_telegram mt-2"></a>
      <a class="a2a_button_facebook mt-2"></a>
      <a class="a2a_button_skype mt-2"></a>
      <a class="a2a_button_wechat mt-2"></a>
      <a class="a2a_dd mt-2" href="https://www.addtoany.com/share"></a>
    </div>
    <component :is="'script'" src="https://static.addtoany.com/menu/page.js" async></component>
    <!-- AddToAny END -->
  </div>
</template>

<script>
export default {
  name: 'addToAny',
  props: {
    link: String
  }
};
</script>
